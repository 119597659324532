<template>
    <div>

        <div class="card card-custom mb-5">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="reset_company">{{ $t('reset_company.company') }}</label>
                            <multiselect
                                id="uuid"
                                v-model="data.uuid"
                                :placeholder="$t('reset_company.company')"
                                :class="validation && validation.uuid ? 'is-invalid' : ''"
                                label="subdomain"
                                track-by="id"
                                :options="companies"
                                :multiple="false"
                                :taggable="false"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false"
                                @search-change="getCompanies($event)">
                            </multiselect>
                            <span v-if="validation && validation.uuid" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.uuid[0] }}
                            </span>
                        </div>
                          <b-form-group label="" v-slot="{ ariaDescribedby }" class="col-12 mt-5">
                            <b-form-checkbox v-model="selectAllChecked" @change="checkAllTables" class="mt-2 mr-2" >{{ $t("select_all") }}</b-form-checkbox>

                            <b-form-checkbox-group id="checkbox-group-2" v-model="data_tables" :aria-describedby="ariaDescribedby" name="content-temp-2" class="row mt-2">
                                <b-form-checkbox v-for="(row, index) in data_tables_list" :value="row.slug" :key="index" class="col-md-5 mt-5 mb-5" >
                                    {{ row.title }}
                                </b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </div>
                    <div class="card-footer pl-0 pr-0">
                        <b-button class="mt-2 mr-1" variant="primary" @click="save">{{
                            $t("save")
                        }}</b-button>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        components: {},
        data() {
            return {
                mainRoute: '/subscription/company_setting/reset',
                mainRouteDependency: 'base/dependency',
                showAdvancedSearch: false,

                data: {
                    uuid : null,
                },

                data_tables: [],
                data_tables_list: [],
                companies: [],
                validation: [],
                selectAllChecked: false,
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.reset_companies")}]);
            this.getCompanies();
            this.resetCompanyData();
        },
        methods: {
            save() {
                ApiService.post(this.mainRoute, {...this.data.uuid,tables: this.data_tables}).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                    this.resetForm();
                }).catch((error) => {
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },

            checkAllTables() {
                if (this.selectAllChecked) {
                    this.data_tables = this.data_tables_list.map((row) => row.slug);
                } else {
                    this.data_tables = [];
                }
            },

            resetCompanyData() {
                ApiService.get(`${this.mainRouteDependency}/reset_company_data`).then((response) => {
                    this.data_tables_list = response.data.data;
                });
            },

            resetForm() {
                this.data = null;
                this.companies = [];
                this.data_tables = null;
            },

            async getCompanies(filter) {
                if (filter && filter.length >= 3) {
                    await ApiService.get(`${this.mainRouteDependency}/tenants/list`, {params: {filter: filter}}).then((response) => {
                       this.companies = response.data.data;
                    });
                } else {
                    this.companies = [];
                }
            },
        },
    };
</script>
